import { __ } from '@wordpress/i18n';
import { useBlockProps, InspectorControls, InspectorAdvancedControls, RichText, MediaUpload, MediaUploadCheck, URLInput } from '@wordpress/block-editor';
import { PanelBody, PanelRow, SelectControl, TextControl, ToggleControl, Tooltip, Button } from '@wordpress/components';
import { useState, Fragment } from "@wordpress/element";
import { withSelect  } from "@wordpress/data";
import './editor.scss';

import IconSelector from "../iconSelector.js";

export default function Edit( props ) {
    const { attributes, setAttributes } = props;
    const {
      isLarge,
      alignLeft,
      backgroundColor,
      mediaId,
      mediaUrl,
      title,
      catchphrase,
      iconName,
      iconColor,
      linkStyle,
      linkUrl,
      linkText,
      linkTarget,
      linkDownload,
      linkRel,
      customAnchor,
      haveIcon
    } = attributes;
    const blockProps = useBlockProps();

    const ctaClass = (!isLarge ? 'cta-large' : 'cta-standard'); /* /!\ "large" correspond à une demi largeur, "standard" est le pleine largeur ! */
    let ctaAlign = '';
    if(!isLarge && alignLeft){
        ctaAlign = ' align-left';
    }
    if(!isLarge && !alignLeft){
        ctaAlign = ' align-right';
    }

    const removeMedia = () => {
		setAttributes({
			mediaId: 0,
			mediaUrl: ''
		});
	}
 
 	const onSelectMedia = (media) => {
		setAttributes({
			mediaId: media.id,
			mediaUrl: media.sizes.full.url
		});
	}

    const [ isOpen, setOpen ] = useState( false );
    const openModal = () => setOpen( true );
    const closeModal = () => setOpen( false );

    return(
        <Fragment>
			<InspectorControls key="setting">
				<PanelBody
					title={ __( 'Paramètre de l\'appel à l\'action', 'gracietco-gut' ) }
					initialOpen={true}
				>
                    <PanelRow>
                        <ToggleControl
                            label={ __( 'Grand format ?', 'gracietco-gut' ) }
                            help={
                                isLarge
                                    ? 'Oui'
                                    : 'Non, simple'
                            }
                            checked={ isLarge }
                            onChange={ () =>{ setAttributes( { isLarge: ! isLarge } ) } }
                        />
                    </PanelRow>
                    { !isLarge ? (
                        <PanelRow>
                            <ToggleControl
                                label={ __( 'Aligné à gauche', 'gracietco-gut' ) }
                                help={
                                    alignLeft
                                        ? 'Aligné à gauche'
                                        : 'Aligné à droite'
                                }
                                checked={ !alignLeft }
                                onChange={ () =>{ setAttributes( { alignLeft: ! alignLeft } ) } }
                            />
                        </PanelRow>
                    ) : ('')}
                    <PanelRow>
                        <SelectControl
                            label={ __( 'Couleur de fond', 'gracietco-gut' ) }
                            value={ backgroundColor } 
                            options={ [
                                { value: 'bg-primary-900', label: 'Couleur principale' },
                                { value: 'bg-secondary-900', label: 'Couleur secondaire' },
                                { value: 'bg-support-900', label: 'Couleur support' },
                                { value: 'bg-gradient-primary', label: 'Dégradé principal' },
                                { value: 'bg-gradient-secondary', label: 'Dégradé secondaire' },
                                { value: 'bg-gradient-support', label: 'Dégradé support' },
                            ] }
                            onChange={ ( option ) => { setAttributes( { backgroundColor: option } ) } }
                        />
                    </PanelRow>
                    <PanelRow>
                    <div className="editor-post-featured-image">
                        <MediaUploadCheck>
                            <MediaUpload
                                onSelect={ onSelectMedia }
                                value={ mediaId }
                                allowedTypes={ ['image'] }
                                render={({open}) => (
									<Button 
										className={mediaId == 0 ? 'editor-post-featured-image__toggle' : 'editor-post-featured-image__preview'}
										onClick={open}
									>
										{mediaId == 0 && __('Choisir une image')}
									</Button>
								)}
                                    
                            />
                        </MediaUploadCheck>
                        {mediaId != 0 && 
							<MediaUploadCheck>
								<MediaUpload
									title={__('Remplacer l\'image')}
									value={mediaId}
									onSelect={onSelectMedia}
									allowedTypes={['image']}
									render={({open}) => (
										<Button onClick={open} isSecondary >{__('Remplacer l\'image')}</Button>
									)}
								/>
							</MediaUploadCheck>
						}
						{mediaId != 0 && 
							<MediaUploadCheck>
								<Button onClick={removeMedia} isLink isDestructive>{__('Supprimer l\'image')}</Button>
							</MediaUploadCheck>
						}
                    </div>
                    </PanelRow>
                      <PanelRow>
                          <ToggleControl
                              label={ __( 'Avec icône', 'gracietco-gut' ) }
                              help={
                                  haveIcon
                                      ? 'Oui'
                                      : 'Non'
                              }
                              checked={ haveIcon }
                              onChange={ () =>{ setAttributes( { haveIcon: ! haveIcon } ) } }
                          />
                      </PanelRow>
                    { haveIcon && (
                    <PanelRow>
                        <SelectControl
                            label={ __( 'Style de l\'icone', 'gracietco-gut' ) }
                            value={ iconColor } 
                            options={ [
                                { value: 'text-', label: 'Remplissage uni' },
                                { value: 'text-gradient-', label: 'Remplissage dégradé' }
                            ] }
                            onChange={ ( option ) => { setAttributes( { iconColor: option } ) } }
                        />
                    </PanelRow>
                    )}
                    </PanelBody>
                    <PanelBody
                        title={ __( 'Paramètre du lien', 'gracietco-gut' ) }
                        initialOpen={false}
                    >
                        <PanelRow>
                            <SelectControl
                                label={ __( 'Quelle est l\'apparence du lien ?', 'gracietco-gut' ) }
                                value={ linkStyle } 
                                options={ [
                                    { value: 'btn btn-primary btn-wave', label: 'Bouton principal' },
                                    { value: 'btn btn-secondary btn-wave', label: 'Bouton secondaire' },
                                    { value: 'btn btn-border-primary btn-wave', label: 'Bouton alternatif' },
                                    { value: '', label: 'Sans mise en forme' },
                                ] }
                                onChange={ ( option ) => { setAttributes( { linkStyle: option } ) } }
                            />
                        </PanelRow>
                        <PanelRow>
                            <URLInput
                                label={ __( 'Cible du lien', 'gracietco-gut' ) }
                                value={ linkUrl }
                                onChange={ (url) => setAttributes( { linkUrl: url } ) }
                            />
                        </PanelRow>
                        <PanelRow>
                            <TextControl
                                label={ __( 'Texte du lien', 'gracietco-gut' ) }
                                value={ linkText }
                                onChange={ linkText => setAttributes( { linkText } )}
                            />
                        </PanelRow>
                        <PanelRow>
                            <ToggleControl
                                label={ __( 'Nouvel onglet ?', 'gracietco-gut' ) }
                                help={
                                    linkTarget
                                        ? 'Oui'
                                        : 'Non'
                                }
                                checked={ linkTarget }
                                onChange={ () =>{ setAttributes( { linkTarget: ! linkTarget } ) } }
                            />
                        </PanelRow>
                        <PanelRow>
                            <ToggleControl
                                label={ __( 'Téléchargement ?', 'gracietco-gut' ) }
                                help={
                                    linkDownload
                                        ? 'Oui'
                                        : 'Non'
                                }
                                checked={ linkDownload }
                                onChange={ () =>{ setAttributes( { linkDownload: ! linkDownload } ) } }
                            />
                        </PanelRow>
                        <PanelRow>
                            <TextControl
                                label={ __( 'Attribut rel', 'gracietco-gut' ) }
                                value={ linkRel }
                                onChange={ linkRel => setAttributes( { linkRel } )}
                            />
                        </PanelRow>
                </PanelBody>
			</InspectorControls>
            <InspectorAdvancedControls>
				<TextControl
					label={ __( 'Ancre flottante', 'gracietco-gut' ) }
					help={ __( 'Positionnez une ancre flottante, un mot ou une phrase simple sans espace ni accentuation, pour pouvoir créer des liens directement vers votre élément.', 'gracietco-gut' ) }
					value={ customAnchor }
					onChange={ customAnchor => setAttributes( { customAnchor } )}
				/>
			</InspectorAdvancedControls>

          <div { ...blockProps }
            className={ blockProps.className}
          >
            <IconSelector
              isOpen={ isOpen }
              closeModal={ closeModal }
              color="text-primary-500"
              setIcon={ (icon) => { setAttributes({iconName:icon}) } }
            />
              { customAnchor.length ? (
                  <span className='anchor'><i id={customAnchor}></i></span>
                  ) : ( '' )
              
              }
              <div className={ ctaClass + ctaAlign }>
                    
                    <div className='cta-container'>

                        { !isLarge && !alignLeft ? (
                            <div className="cta-spacer"></div>
                        ) : ('')}

                        { !isLarge ? (
                            <div className='cta-wrapper'>
                              { haveIcon ? (
                                  <p>
                                      <Tooltip text={ __( 'Changer l\'icône' ) }>
                                          <Button 
                                              label='Choix icône'
                                              aria-haspopup="true"
                                              tooltip={__('Insérer une icône')}
                                              onClick={ openModal }
                                          >
                                              { iconName.length ? (
                                                  <i className={'material-icons md-48 '+ iconColor + (backgroundColor == "support" ? (iconColor.includes("gradient") ? "primary" : "primary-600") : (iconColor.includes("gradient") ? "support" : "white")) } aria-hidden="true">{ iconName }</i>
                                                  ) : (
                                                      <span className="dashicon dashicons dashicons-flag"></span>
                                                  )

                                              }
                                          </Button>
                                      </Tooltip>
                                  </p>
                              ) : ('')}
                                <RichText
                                    tagName='p'
                                    className={['cta-title', (backgroundColor == "support") ? "text-primary-600":"text-white"]}
                                    placeholder={__('Titre')}
                                    value={title}
                                    onChange={ ( value ) => { setAttributes( { title: value } ) } }
                                    allowedFormats={ [] }
                                />
                                <RichText
                                    tagName='div'
                                    className={['cta-catchphrase', (backgroundColor == "support") ? "text-primary-600":"text-white"] }
                                    placeholder={__('Accroche')}
                                    value={catchphrase}
                                    onChange={ ( value ) => { setAttributes( { catchphrase: value } ) } }
                                    allowedFormats={ ['core/bold', 'core/italic'] }
                                    multiline="p"
                                />
                                <p className="cta-link">
                                    <a
                                        className={ linkStyle} 
                                        href={ linkUrl }
                                        target={ linkTarget ? '_blank' : undefined }
                                        download={ linkDownload ? 'true' : undefined }
                                        rel={ linkRel && linkRel.length ? linkRel : undefined }
                                    >
                                        { linkText }
                                    </a> 
                                </p>
                            </div>
                        ) : (
                            <>
                              { haveIcon ? (
                                  <p>
                                      <Tooltip text={ __( 'Changer l\'icône' ) }>
                                          <Button 
                                              label='Choix icône'
                                              aria-haspopup="true"
                                              tooltip={__('Insérer une icône')}
                                              onClick={ openModal }
                                          >
                                              { iconName.length ? (
                                                  <i className={'material-icons md-48 '+ iconColor + (backgroundColor == "support" ? (iconColor.includes("gradient") ? "primary" : "primary-600") : (iconColor.includes("gradient") ? "support" : "white")) } aria-hidden="true">{ iconName }</i>
                                                  ) : (
                                                      <span className="dashicon dashicons dashicons-flag"></span>
                                                  )

                                              }
                                          </Button>
                                      </Tooltip>
                                  </p>
                              ) : ('')}
                            <RichText
                                tagName='p'
                                className={['cta-title', (backgroundColor == "support") ? "text-primary-600":"text-white"]}
                                placeholder={__('Titre')}
                                value={title}
                                onChange={ ( value ) => { setAttributes( { title: value } ) } }
                                allowedFormats={ [] }
                            />
                            <RichText
                                tagName='div'
                                className={['cta-catchphrase', (backgroundColor == "support") ? "text-primary-600":"text-white"] }
                                placeholder={__('Accroche')}
                                value={catchphrase}
                                onChange={ ( value ) => { setAttributes( { catchphrase: value } ) } }
                                allowedFormats={ ['core/bold', 'core/italic'] }
                                multiline="p"
                            />
                            <p className="cta-link">
                                <a
                                    className={ linkStyle} 
                                    href={ linkUrl }
                                    target={ linkTarget ? '_blank' : undefined }
                                    download={ linkDownload ? 'true' : undefined }
                                    rel={ linkRel && linkRel.length ? linkRel : undefined }
                                >
                                    { linkText }
                                </a> 
                            </p>
                            </>
                        )}
                        { !isLarge ? (
                            <div className={'cta-bg ' + backgroundColor + ' opacity-85'}></div>
                        ) : ('')}
                        { !isLarge && alignLeft ? (
                            <div className="cta-spacer"></div>
                        ) : ('')}
                    </div>
                    
                    { isLarge ? (
                        <div className={'cta-bg ' + backgroundColor + ' opacity-85'}></div>
                    ) : ('')}
                    
                    { mediaUrl.length ? (
                        <img
                            src={mediaUrl}
                            className={'cta-image wp-image-' + mediaId} />
                    ) : ( '' )
                    }
                </div>
            </div>
        </Fragment>
    )
}
