import { __ } from '@wordpress/i18n';
import { useBlockProps, RichText } from '@wordpress/block-editor';

/**
 * The save function defines the way in which the different attributes should
 * be combined into the final markup, which is then serialized by the block
 * editor into `post_content`.
 *
 * @see https://developer.wordpress.org/block-editor/developers/block-api/block-edit-save/#save
 *
 * @return {WPElement} Element to render.
 */

export default function save( props ) {
	const { attributes} = props;
    const { isLarge, alignLeft, backgroundColor, mediaId, mediaUrl, title, catchphrase, iconName, iconColor, linkStyle, linkUrl, linkText, linkTarget, linkDownload, linkRel, customAnchor, haveIcon } = attributes;
	const blockProps = useBlockProps.save()
    const ctaClass = (!isLarge ? 'cta-large' : 'cta-standard');
    let ctaAlign = '';
    if(!isLarge && alignLeft){
        ctaAlign = ' align-left';
    }
    if(!isLarge && !alignLeft){
        ctaAlign = ' align-right';
    }

    return (
		<div { ...blockProps }
			className={ blockProps.className }
		>
            { customAnchor.length ? (
                <span className='anchor'><i id={customAnchor}></i></span>
                ) : ( '' )
            
            }
            <div className={ ctaClass + ctaAlign }>
                <div className='cta-container'>

                    { !isLarge && !alignLeft ? (
                        <div className="cta-spacer"></div>
                    ) : ('')}
                    { !isLarge ? (
                        <div className='cta-wrapper'>
                          { haveIcon && iconName.length ? (
                              <p>
                                <i className={'material-icons md-48 '+ iconColor + (backgroundColor == "support" ? (iconColor.includes("gradient") ? "primary" : "primary-600") : (iconColor.includes("gradient") ? "support" : "white")) } aria-hidden="true">{ iconName }</i>
                              </p>
                          ) : ('')}
                            <RichText.Content
                                tagName='p'
                                className={'cta-title '+((backgroundColor == "support") ? "text-primary-600":"text-white")}
                                value={title}
                            />
                            <RichText.Content
                                tagName='div'
                                className={'cta-catchphrase '+((backgroundColor == "support") ? "text-primary-600":"text-white")}
                                value={catchphrase}
                                multiline="p"
                            />
                            <p className="cta-link">
                                <a
                                    className={ linkStyle} 
                                    href={ linkUrl }
                                    target={ linkTarget ? '_blank' : undefined }
                                    download={ linkDownload ? 'true' : undefined }
                                    rel={ linkRel && linkRel.length ? linkRel : undefined }
                                >
                                    { linkText }
                                </a> 
                            </p>
                        </div>
                    ) : (
                        <>
                        { haveIcon && iconName.length ? (
                            <p>
                              <i className={'material-icons md-48 '+ iconColor + (backgroundColor == "support" ? (iconColor.includes("gradient") ? "primary" : "primary-600") : (iconColor.includes("gradient") ? "support" : "white")) } aria-hidden="true">{ iconName }</i>
                            </p>
                        ) : ('')}
                        <RichText.Content
                            tagName='p'
                            className={'cta-title '+((backgroundColor == "support") ? "text-primary-600":"text-white")}
                            value={title}
                        />
                        <RichText.Content
                            tagName='div'
                            className={'cta-catchphrase '+((backgroundColor == "support") ? "text-primary-600":"text-white")}
                            value={catchphrase}
                            multiline="p"
                        />
                        <p className="cta-link">
                            <a
                                className={ linkStyle} 
                                href={ linkUrl }
                                target={ linkTarget ? '_blank' : undefined }
                                download={ linkDownload ? 'true' : undefined }
                                rel={ linkRel && linkRel.length ? linkRel : undefined }
                            >
                                { linkText }
                            </a> 
                        </p>
                        </>
                    )}
                    { !isLarge ? (
                        <div className={'cta-bg ' + backgroundColor + ' opacity-85'}></div>
                    ) : ('')}
                    { !isLarge && alignLeft ? (
                        <div className="cta-spacer"></div>
                    ) : ('')}
                </div>
            </div>
            { isLarge ? (
                <div className={'cta-bg ' + backgroundColor + ' opacity-85'}></div>
            ) : ('')}
            
            { mediaUrl.length ? (
                <img
                    src={mediaUrl}
                    className={'cta-image wp-image-' + mediaId} 
                    alt=""/>
            ) : ( '' )
            }
        </div>
    );
}
